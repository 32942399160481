import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import qs from "query-string"

import Medusa from "../../services/api"
import SEO from "../../components/seo"
import LoadingSpinner from "../../components/ui/LoadingSpinner"
import Typography from "../../components/ui/Typography"
import CheckoutLayout from "../../components/checkout/checkout-layout"

const DetailContainer = styled.div`
  margin: 0 auto;
  margin-top: 5rem;
  a {
    color: ${(props) => props.theme.colors.primary};
  }
`

const Details = styled.div`
  h1 {
    ${Typography.Large}
    font-weight: normal;
  }
`

class CheckoutDone extends React.Component {
  state = {
    swap: {},
  }

  async componentDidMount() {
    const { sct } = qs.parse(this.props.location.search)

    let swap

    try {
      swap = await Medusa.swaps.retrieveByCartId(sct)
      this.setState({
        swap: data.swap,
      })
    } catch (error) {
      await Medusa.cart.completeCart(sct).then(({ data }) => {
        this.setState({
          swap: data.data,
        })
      })
    }
  }

  render() {
    const { t } = this.props
    const { swap } = this.state
    return (
      <CheckoutLayout>
        <SEO title="Swap Completed" />
        {!swap.id ? (
          <LoadingSpinner />
        ) : (
          <DetailContainer>
            {swap && (
              <div>
                <Details>
                  <h1>{`Thank you, ${swap.shipping_address.first_name}!`}</h1>
                  <div>
                    Your payment has been received. We will process your swap as
                    soon as we receive your return.
                  </div>
                </Details>
              </div>
            )}
            <Link to="/">Back to teklafabrics.com</Link>
          </DetailContainer>
        )}
      </CheckoutLayout>
    )
  }
}

export default CheckoutDone
